// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//

require("@rails/ujs")
// require("turbolinks").start()
// require("@rails/activestorage").start()
import "trix"
import "@rails/actiontext"
require("packs/tools")
require("packs/console")
// import "chartkick/chart.js"

// start raisl ujs
import Rails from "@rails/ujs"
Rails.start()

import * as bootstrap from 'bootstrap'

$(function(){
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  });

	$(".alert-dismissible").length && setTimeout(function () {
		$(".alert-dismissible").slideUp(function () {
			$(this).remove();
		});
	}, 10e3);

  $('input[type=text]').attr('autocomplete', 'off')
	$('form.grid-data-form').on('change', function (event) {
		$(event.currentTarget).trigger('submit')
	});
});

