
$(document).on('click', '#rsq_send', () => {

	let rsq = {};
	rsq.url = $('#rsq_url').val();
	rsq.type = $('#rsq_type').val();

	rsq.headers = {};
	$("#rsq_form").find("input[name='rsq_header_name[]']").each((i, e) => {
		if ($(e).val()) {
			rsq.headers[$(e).val()] = $("#rsq_form").find("input[name='rsq_header_value[]']").eq(i).val();
		}
	});

	rsq.headers['Accept'] = 'application/json, text/plain, */*';
	rsq.data = $('#rsq_body').val();
	rsq.contentType = 'application/json';

	rsq.beforeSend = () => {
		$('#rsp_headers').html('');
		$('#rsp_body').html('');
		$('#rsp-body-title').html('');
	};

	var jqXHR = $.ajax(rsq);

	jqXHR.done((data, textStatus, jqXHR) => {
		$('#rsp_body').html(JSON.stringify(data, null, 2));
		$('#rsp_headers').html(jqXHR.getAllResponseHeaders());
		$('#rsp-body-title').html('Status: ' + jqXHR.status);
	});

	jqXHR.fail(function (jqXHR, textStatus, errorThrown) {
		$('#rsp_body').html(JSON.stringify(jqXHR.responseJSON, null, 2));
		$('#rsp_headers').html(jqXHR.getAllResponseHeaders());
		$('#rsp-body-title').html('Status: ' + jqXHR.status);
	});

});
